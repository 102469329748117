<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white is-uppercase" style="padding: 5px 0px">{{ pageheading }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <form @submit.prevent="handleSubmitLocal('/users', {name:'Users'})">
          <div class="button-group">
              <button :disabled="!$hasPermissions(clientSession, ['USERS'], 2) || emailExists" type="submit" class="button is-accent has-text-white">Save</button>
              <button class="button is-light" @click="resetForm(); $router.go(-1)" type="button">Cancel</button>
              <button
                class="button"
                :class="isCreateMode ? 'is-hidden' : 'is-accent'"
                :disabled="isCreateMode || !$hasPermissions(clientSession, ['USERS'], 2)"
                @click="isCreateMode ? 'do nothing' : input.isLockedOut ? unlock() : lock()"
              >{{input.isLockedOut ? 'Unlock This Acct' : 'Lock This Acct'}}</button>
          </div>
            <div class="columns is-clearfix">
              <div class="column is-one-third">
                <div
                  class="block"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                >
                  <span class="has-text-weight-bold">User Properties</span>
                </div>
                <!-- ====== USER PROPERTIES ====== -->
                <div class="block">
                  <div>
                    <div class="field is-grouped">
                      <div class="control">
                        <label class="label is-size-7">
                          First Name
                          <span class="has-text-warning" style="padding-left: 3px;">*</span>
                        </label>
                        <input :class="{ 'input': true, 'is-danger': errors.lastName !== undefined }" type="text" name="firstName" v-model="input.firstName" />
                      </div>

                      <div class="control">
                        <label class="label is-size-7">
                          Last Name
                          <span class="has-text-warning" style="padding-left: 3px;">*</span>
                        </label>
                        <input :class="{ 'input': true, 'is-danger': errors.firstName !== undefined }" type="text" name="lastName" v-model="input.lastName" />
                      </div>
                    </div>

                    <div class="field">
                      <label class="label is-size-7">
                        Email
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input
                          :class="{ 'input': true, 'is-danger': emailExists || errors.email !== undefined }"
                          type="text"
                          name="email"
                          v-model="input.email"
                          :disabled="!isCreateMode"
                        />
                      </div>
                      <label v-if="emailExists" class="label is-size-7 has-text-danger">
                        {{errors.email ? errors.email : emailExists ? 'Email is already in use. ' : ''}}
                      </label>
                    </div>

                    <div class="field">
                      <label class="label is-size-7">
                        Role
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control inline">
                        <div :class="{'select': true, 'is-danger': errors.role !== undefined}" @change="getActiveRole($event)">
                          <select v-model="input.role">
                            <option v-for="role in roles" :key="role" :value="role">{{role}}</option>
                          </select>
                        </div>
                      </div>
                      <div v-if="input.role != 'Client' && input.role != ''">
                        <input
                          type="checkbox"
                          id="isTeamMember"
                          name="isTeamMember"
                          v-model="input.isTeamMember"
                          @click="input.isTeamMember = !input.isTeamMember"
                        />
                        <label
                          for="isTeamMember"
                          class="is-size-7"
                          style="padding: 0px 7px;"
                        >User is a Team Member</label>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <!-- ====== PASSWORD RESET ====== -->
                <div class="block" v-if="!isCreateMode">
                  <div
                    class="block"
                    style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                  >
                    <span class="has-text-weight-bold">Password Reset</span>
                  </div>
                  <div class="block">
                    <div>
                      <div class="field is-grouped">
                        <div class="control">
                          <div class="field">
                            <b-radio
                              v-model="passwordResetRadio"
                              native-value="resetEmail"
                              type="is-accent"
                            >Send password reset email</b-radio>
                          </div>
                          <div class="field">
                            <b-radio
                              v-model="passwordResetRadio"
                              native-value="setPassword"
                              type="is-accent"
                            >Manually set password</b-radio>
                          </div>
                        </div>
                      </div>
                      <div v-show="passwordResetRadio === 'setPassword'">
                        <div class="field" style="padding-left: 2rem;">
                          <label class="label is-size-7">New Password</label>
                          <div class="control">
                            <input class="input" type="text" name="password" v-model="newPassword" />
                          </div>
                        </div>
                        <div style="padding: 10px 0px 20px 40px;" class="is-size-7">
                          <div
                            style="padding-left: 40px;"
                            v-for="item in passwordValidation"
                            :key="item.msg + item.isValid"
                          >
                            <i
                              :class="item.isValid ? 'far fa-check-circle has-text-accent': 'fas fa-times has-text-danger'"
                            ></i>
                            : {{ item.msg }}
                          </div>
                          <div style="padding-top: 20px;">
                            <input
                              type="checkbox"
                              id="forceChange"
                              name="forceChange"
                              v-model="forceChange"
                            />
                            <label
                              for="forceChange"
                              class="is-size-7"
                              style="padding: 0px 7px;"
                            >Force user to change password on next login</label>
                          </div>
                        </div>
                      </div>

                      <div class="control buttons" style="width:100%; margin-left:2rem">
                        <a
                          class="button"
                          :class="[isCreateMode ? 'is-hidden' : 'is-accent', !$hasPermissions(clientSession, ['USERS'], 2) ? 'disabled' : '']"
                          :disabled="isCreateMode"
                          @click="isCreateMode ? 'do nothing' : !$hasPermissions(clientSession, ['USERS'], 2) ? null : passwordReset()"
                        >Reset Password</a>

                        <a
                          class="button is-light"
                          :class="{ 'hidden' : isCreateMode, 'is-loading': isDeletingMFAMethods, 'disabled' : !$hasPermissions(clientSession, ['USERS'], 2) }"
                          @click="isCreateMode ? 'do nothing' : !$hasPermissions(clientSession, ['USERS'], 2) ? null : mfaReset()"
                        >
                          <i v-if="hasResetMFA" class="fa fa-check"></i>
                          <span v-else>Reset MFA</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column">
                <div
                  v-if="activeRole >= 0 && activeRole != 2"
                  class="block"
                  style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                >
                  <span class="has-text-weight-bold">Role Properties</span>
                </div>
                <div class="block">
                  <!-- ======= CLIENT PROPERTIES ====== -->
                  <div v-if="input.role==='Client'" style="margin-bottom: 40px;">
                    <Client
                      :model="this.membershipInput"
                      :errors="errors"
                      @membershipUpdated="onMembershipUpdated"
                    />
                  </div>

                  <div v-if="['Staff', 'Manager'].includes(input.role) && $hasPermissions(clientSession, ['PERMISSION_TEMPLATES', 'USERS'], 1)">

                    <div
                      class="block"
                      style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                    >
                      <span class="has-text-weight-bold">Permissions</span>
                    </div>

                    <div class="block" style="margin-bottom: 3rem;">
                      <div class="field">
                        <label class="label is-size-7">
                          Default Permission Template
                          <span class="has-text-warning" style="padding-left: 3px;">*</span>
                        </label>
                        <div class="control inline">
                            <div >
                              <searchable-select
                                :disabled="clientSession.userId === input.id"
                                :options="permissionTemplateOptions"
                                :selected="input.permissions"
                                :multiple="true"
                                :searchable="true"
                                :clearable="true"
                                :placeholder="'Select Permissions'"
                                :isDefault="false"
                                @selected="onPermissionsChanged"
                              />
                            </div>
                        </div>
                      </div>
                      <table class="table table-striped" style="width: 100%;">
                        <tr>
                          <th style="width: calc(100% - 30%)">Section</th>
                          <th style="width: 10%">None</th>
                          <th style="width: 10%">Read</th>
                          <th style="width: 10%">Read/Write</th>
                        </tr>
                        <tr class="field" v-for="permission in permissions" :key="permission.id">
                          <td style="color: #454545!important;">
                            {{permission.name}}
                          </td>
                          <td @click="permission.access = 0">
                            <input :disabled="clientSession.userId === input.id" type="radio" :ref="permission.code" :name="permission.code" :value="0" :checked="permission.access === 0">
                          </td>
                          <td @click="permission.access = 1">
                            <input :disabled="clientSession.userId === input.id" type="radio" :ref="permission.code" :name="permission.code" :value="1" :checked="permission.access === 1">
                          </td>
                          <td @click="permission.access = 2">
                            <input :disabled="clientSession.userId === input.id" type="radio" :ref="permission.code" :name="permission.code" :value="2" :checked="permission.access === 2">
                          </td>
                      </tr>
                      </table>
                    </div>

                  </div>

                  <!-- ======= OUTSIDE SALES REP PROPERTIES ====== -->
                  <div v-if="input.role==='Outside Sales Rep'"  style="margin-bottom: 40px;">
                    <OutsideSalesRep
                      :model="this.membershipInput"
                      :_errors="errors"
                      @membershipUpdated="onMembershipUpdated"
                    />
                  </div>

                  <!-- ======= TEAM MEMBER PROPERTIES ====== -->
                  <!-- pull back to component when backed by vuex -->
                  <div v-if="input.isTeamMember" >
                    <TeamMember
                      :model="this.inputRo"
                      :_errors="errors"
                      @teamInfoUpdated="onTeamInfoUpdate"
                      @avatarChange="onAvatarChange"
                    />
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import TeamMember from './EditRoleTeamMember'
import Client from './EditRoleClient'
import OutsideSalesRep from './EditRoleOutsideSales'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import SearchableSelect from './partials/dropdowns/SearchableDropdown.vue'
import moment from 'moment-timezone'

let pageName = 'User Details'

export default {
  components: {
    TeamMember,
    Client,
    OutsideSalesRep,
    Loading,
    SearchableSelect
  },
  props: {
    model: {
      default: () => {
        return {
          passwordResetRadio: '',
          id: 0,
          displayName: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          title: '',
          bio: '',
          extension: '',
          permissions: [],
          input: {
            permissions: null
          },
          role: '',
          isTeamMember: false,
          isLockedOut: false,
          institutionId: 0
        }
      }
    }
  },
  data () {
    return {
      isDeletingMFAMethods: false,
      hasResetMFA: false,
      input: {
        displayName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        title: '',
        bio: '',
        extension: '',
        role: '',
        isTeamMember: false,
        isLockedOut: false,
        institutionId: 0
      },
      errors: {},
      emailExists: false,
      siteSettings: {},
      newPassword: '',
      permissionTemplates: [],
      permissions: [],
      forceChange: false,
      passwordResetRadio: 'resetEmail',
      membershipInput: {
        userId: 0,
        institutionIds: [],
        reportTypeCodes: []
      },
      inputDefault: {},
      isLoading: false,
      roles: ['Client', 'Outside Sales Rep', 'Staff', 'Manager'],
      activeRole: '-1',
      avatar: undefined,
      pageheading: pageName
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    inputRo () {
      return Object.assign({}, this.input)
    },
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      return !id || id <= 0
    },
    permissionTemplateOptions () {
      if (this.permissionTemplates.length > 0) {
        return [{ name: 'Select Template', id: 0 }, ...this.permissionTemplates]
      } else {
        return []
      }
    },
    passwordValidation () {
      return this.validatePassword(
        this.newPassword,
        this.newPassword,
        this.siteSettings,
        false
      )
    },
    isAccountLocked () {
      return this.input.status === 1
    }
  },
  async mounted () {
    await this.getPermissions()
    await this.getPermissionTemplates()
  },
  async created () {
    await this.setup()
  },
  async beforeRouteEnter (to, from, next) {
    if (to.params.skip) { localStorage.setItem('userSkip', to.params.skip) }

    let user = null

    next(vm => {
      if (!vm.isCreateMode && to.params.model) { user = JSON.parse(to.params.model) }

      if (user) {
        vm.input = { ...user } // Make sure to destructure and clone. Otherwise, the input will be overwritten.
        vm.input.avatarPath = user.avatarPath ? user.avatarPath + '?timestamp=' + Math.floor(Date.now() / 1000) : ''
      }
    })
  },
  watch: {
    'input.email': async function (email) {
      if (this.isEmail(email) && this.isCreateMode) {
        try {
          let result = await this.api().checkEmail(email)
          if (result && result.response.status === 200) {
            let onBoth = result.response.sites.length === 2
            this.errors.email = `Email in use on ${onBoth ? 'both' : (result.response.sites[0] === 2 ? 'Business' : 'Banking')} Site${onBoth ? 's' : ''}`
            this.emailExists = true
          } else {
            this.errors.email = undefined
            this.emailExists = false
          }

          this.$forceUpdate()
        } catch (error) {
          this.errors.email = undefined
          this.emailExists = false
          this.$forceUpdate()
        }
      }
    }
  },
  methods: {
    getActiveRole (event) {
      this.activeRole = event.target.value
    },
    async lock () {
      try {
        this.isLoading = true
        let route = `/user/${this.$route.params.id}/lock`
        await this.axios.post(route)
        this.successToast('Account Locked!')
        await this.loadModel('/users')
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getPermissions () {
      this.permissions = [
        {
          id: 0,
          name: 'Client Reports',
          code: 'CLIENT_REPORTS'
        },
        {
          id: 1,
          name: 'Document Center',
          code: 'DOCUMENT_CENTER'
        },
        {
          id: 2,
          name: 'Announcements',
          code: 'ANNOUNCEMENTS'
        },
        {
          id: 3,
          name: 'Email Templates',
          code: 'EMAIL_TEMPLATES'
        },
        {
          id: 4,
          name: 'Carriers',
          code: 'CARRIERS'
        },
        {
          id: 5,
          name: 'Institutions',
          code: 'INSTITUTIONS'
        },
        {
          id: 6,
          name: 'Report Types',
          code: 'REPORT_TYPES'
        },
        {
          id: 7,
          name: 'Client Menu',
          code: 'CLIENT_MENU'
        },
        {
          id: 8,
          name: 'Sites',
          code: 'SITES'
        },
        {
          id: 9,
          name: 'Permission Templates',
          code: 'PERMISSION_TEMPLATES'
        },
        {
          id: 10,
          name: 'Users',
          code: 'USERS'
        },
        {
          id: 11,
          name: 'Activity Report',
          code: 'ACTIVITY_REPORT'
        },
        {
          id: 12,
          name: 'Asset Reporting',
          code: 'ASSET_REPORT'
        },
        {
          id: 14,
          name: 'Report Configuration',
          code: 'REPORT_CONFIGURATION'
        },
        {
          id: 15,
          name: '8925 Reporting',
          code: 'C8925_REPORT'
        },
        {
          id: 16,
          name: 'Summary Asset Report',
          code: 'ASSET_SUMMARY_REPORT'
        },
        {
          id: 17,
          name: 'Annual Questionnaire',
          code: 'ANNUAL_QUESTIONNAIRE'
        },
        {
          id: 18,
          name: 'Annual Boli Review',
          code: 'ANNUAL_BOLI_REVIEW'
        }
      ]

      // check if the form is loaded for new user
      if (this.$route.params.id) {
        let results = await this.api().permissions().getUserPermissions(this.$route.params.id)

        if (results && results.data && results.data.length > 0) {
          results.data.forEach(permission => {
            if (permission) {
              let permissions = this.permissions.map(p => {
                if (p.code === permission.code) {
                  p = {
                    ...p,
                    ...permission
                  }
                }

                return p
              })

              this.permissions = permissions
            }
          })
        }
      }
    },
    async getPermissionTemplates () {
      if (this.$hasPermissions(this.clientSession, ['PERMISSION_TEMPLATES', 'USERS'])) {
        try {
          let result = await this.api().permissions().getPermissionTemplates()
          this.permissionTemplates = result.data.data
        } catch (error) {
          this.handleApiErr(error)
        }
      }
    },
    async unlock () {
      try {
        this.isLoading = true
        let route = `/user/${this.$route.params.id}/lock`
        await this.axios.delete(route)
        this.successToast('Account Unlocked!')
        await this.loadModel('/users')
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async passwordReset () {
      try {
        this.isLoading = true
        if (this.passwordResetRadio === 'resetEmail') {
          let route = `/user/send-password-reset-email`
          await this.axios.post(route, null, {
            params: { email: this.input.email }
          })
          this.successToast('Password reset email sent')
        } else {
          if (
            this.passwordValidation.some(x => !x.isValid) ||
            (this.input.newPassword && this.input.newPassword.trim() === '')
          ) {
            this.errorToast("Password doesn't meet requirements or is blank")
            return
          }
          let userId = this.$route.params.id
          let route = `/users/${userId}/password`
          let payload = {
            newPassword: this.newPassword,
            forceChange: this.forceChange
          }

          await this.axios.put(route, payload)
          this.successToast('Password reset!')
        }
        this.newPassword = ''
        this.passwordResetRadio = 'resetEmail'
        this.forceChange = false
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getMembership (userId) {
      try {
        this.isLoading = true
        let route = `memberships/${userId}`
        let response = await this.axios.get(route)

        if (response.data) { this.membershipInput = response.data }
      } finally {
        this.isLoading = false
      }
    },
    async updatePermissions () {
      if (this.$hasPermissions(this.clientSession, ['PERMISSION_TEMPLATES', 'USERS'], 2)) {
        let selection = []

        this.permissions.map(__permission => {
          selection.push({
            code: __permission.code,
            level: __permission.access
          })
        })

        this.isLoading = true
        let userId = this.$route.params.id || this.membershipInput.userId
        let response = await this.api().permissions().setUserPermissions(userId, selection)

        if (response.status === 200) {
          this.successToast('Permissions updated!')
        } else {
          this.errorToast('Error updating permissions')
        }
      }

      this.isLoading = false
    },
    async onPermissionsChanged (e) {
      // eslint-disable-next-line no-unused-vars
      let permissionTemplate = {}

      this.permissionTemplates.forEach(__permissionTemplate => {
        if (__permissionTemplate.id === e.id) {
          permissionTemplate = __permissionTemplate
        }
      })

      try {
        permissionTemplate.permissions = (await this.api().permissions().getPermissionTemplate(permissionTemplate.id)).data.data.permissions
      } catch (e) {
        // Do nothing
      }

      if (permissionTemplate && permissionTemplate.permissions) {
        let newPermissions = []

        this.permissions.map(__permission => {
          let permissionTemplatePermissionValue = parseInt(permissionTemplate.permissions[__permission.code])
          __permission.access = permissionTemplatePermissionValue
          newPermissions.push(__permission)
        })

        this.permissions = newPermissions
      }
    },
    async handleSubmitLocal (route, nav) {
      this.errors = {}

      if (!this.input.firstName) {
        this.errors.firstName = 'Missing First Name'
      }

      if (!this.input.lastName) {
        this.errors.lastName = 'Missing Last Name'
      }

      if (!this.input.email) {
        this.errors.email = 'Missing Email'
      }

      if (!this.input.role) {
        this.errors.role = 'Missing Role'
      }
      // alert(this.input.role.toLowerCase())
      if (this.input.role.toLowerCase() === 'client') {
        if (
          this.membershipInput.institutionIds.length === 0 ||
          this.membershipInput.institutionIds[0] === 0
        ) {
          this.errors.institutionId = 'Missing Institution'
        }

        if (
          this.membershipInput.reportTypeCodes.length === 0
        ) {
          this.errors.reportTypeCodes = 'Missing Report Types'
        }
      }

      if (this.input.role.toLowerCase() === 'outside sales rep') {
        if (
          this.membershipInput.institutionIds.length === 0 ||
          this.membershipInput.institutionIds[0] === 0
        ) {
          this.errors.institutionId = 'Missing Institution'
        }

        if (
          this.membershipInput.reportTypeCodes.length === 0
        ) {
          this.errors.reportTypeCodes = 'Missing Report Types'
        }
      }

      if (this.input.isTeamMember) {
        if (!this.input.phoneNumber) {
          this.errors.phoneNumber = 'Missing Phone Number'
        }

        if (!this.input.phoneNumber) {
          this.errors.displayName = 'Missing Display Name'
        }
      }

      if (Object.keys(this.errors).length > 0) {
        this.errorToast('Form is missing some fields.')
        this.$forceUpdate()
        return
      }

      this.isLoading = true
      let user = await this.handleSubmit(route)
      this.isLoading = false
      if (!user || user == null || user.id < 0) {
        return
      }
      this.membershipInput.userId = user.id
      await this.updateMembership()
      await this.updatePermissions()

      this.isLoading = true
      if (await this.uploadAvatar(user.id)) {
        this.successToast('Saved user profile picture!', -1)
      } else if (this.avatar && (this.avatar.size < 1)) {
        this.errorToast('Unable to save profile picture.')
        this.isLoading = false
      }
      // this.$router.push({ name: 'Users' })
      this.isLoading = false
    },
    async setPermissions () {
      this.permissions.map(__permission => {
        this.$refs[__permission.code].map(input => {
          input.setAttribute('checked', 0)

          let value = parseInt(__permission.access)

          if (parseInt(input.value) === value) {
            input.setAttribute('checked', 1)
          }
        })
      })
    },
    async updateMembership () {
      try {
        if (['Staff', 'Manager'].includes(this.input.role)) { return }

        this.isLoading = true
        let route = `memberships/${this.membershipInput.userId}`
        let payload2 = JSON.parse(JSON.stringify(this.membershipInput))

        let response = await this.axios.put(route, payload2)

        this.membershipInput = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async uploadAvatar (userId) {
      if (!this.avatar || this.avatar.size < 1) {
        return
      }

      let payload = new FormData()
      payload.append('image', this.avatar)

      try {
        let response = await this.axios.post(`/user/${userId}/avatar`, payload)

        if (response.status !== 200) {
          this.handleApiErr(response.data)
          return false
        } else {
          return true
        }
      } catch (error) {
        // this.handleApiErr(error)
        return false
      }
    },
    onAvatarChange (event, testing) {
      this.avatar = event
    },
    onMembershipUpdated (event) {
      this.membershipInput = event
    },
    onTeamInfoUpdate (event) {
      this.input.displayName = event.displayName
      this.input.title = event.title
      this.input.phoneNumber = event.phoneNumber
      this.input.picture = event.picture
      this.input.bio = event.bio
    },
    mfaReset () {
      this.isDeletingMFAMethods = true
      this.api().deleteMFA(this.input.id, (response, error) => {
        if (error) {
          // this.errors = error
        } else {
          this.successToast('MFA Reset!')

          this.hasResetMFA = true

          const timeout = () => {
            this.hasResetMFA = false

            this.input.isMFAEnabled = false
            this.input.mfaChannel = null
            this.input.mfaExpiration = moment('2000-01-01T00:00:00.000Z')
            this.input.phoneNumber = null
            this.input.mfaSessionId = null
          }

          this.input.isMFAEnabled = false
          this.input.mfaChannel = null
          this.input.mfaExpiration = moment('2000-01-01T00:00:00.000Z')
          this.input.phoneNumber = null
          this.input.mfaSessionId = null

          setTimeout(timeout.bind(this), 1500)
        }

        this.isDeletingMFAMethods = false
      })
    },
    async setup () {
      // if external direct link, need to load the model state
      if (!this.isCreateMode && (!this.model || this.model.id <= 0)) {
        await this.loadModel('/users')
      } else {
        await this.setupForm(this.model)
      }

      // fetch membership info if editing user
      if (!this.isCreateMode) {
        await this.getMembership(this.$route.params.id)
      }
      this.activeRole = this.model.role
      if (this.activeRole === '') {
        this.activeRole = -1
      }
      this.getSiteSettings()
    }
  }
}
</script>

<style scoped>
  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;

    width: 100%;
  }

  @media screen and (max-width: 1080px) {
    .columns {
      flex-direction: column!important;
    }

    .columns .column:first-of-type {
      max-width: 100%;
      padding-left: .5rem;
    }
  }

  .columns .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin-bottom: 1rem;
  }

  .columns .column:first-of-type {
    max-width: 40%;
  }

  .columns .column .block {
    max-width: 100%;
    display: flex!important;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    overflow: hidden;
  }

  .field.is-grouped {
    display: flex!important;
    flex-direction: row!important;
    flex-wrap: wrap!important;
    justify-content: space-between!important;
    align-items: flex-start!important;
    align-content: flex-start!important;
    padding-left: 5px;
  }

</style>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
</style>
