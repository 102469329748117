<template>
  <div>
    <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
      <span class="has-text-weight-bold">Team Member Properties</span>
    </div>
    <div class="block">
      <div class="columns team-member-properties">
        <div class="column " style="padding-left: 10px;">
          <div class="field">
            <label class="label is-size-7">
              Display Name
              <span class="has-text-warning" style="padding-left: 3px;">*</span>
            </label>
            <div class="control">
              <input
                :class="{ 'input': true, 'is-danger': _errors.displayName !== undefined }"
                class="input"
                type="text"
                name="displayName"
                @blur="updateTeamInfo"
                v-model="input.displayName"
              >
            </div>
          </div>
          <div class="field is-grouped">
            <div class="field">
              <label class="label is-size-7">Title</label>
              <div class="control">
                <input

                  class="input"
                  type="text"
                  name="team_member_title"
                  @blur="updateTeamInfo"
                  v-model="input.title"
                >
              </div>
            </div>
            <div class="field">
              <label class="label is-size-7">
                Phone Number
                <span class="has-text-warning" style="padding-left: 3px;">*</span>
              </label>
              <div class="control">
                <input
                  :class="{ 'input': true, 'is-danger': _errors.phoneNumber !== undefined }"
                  class="input"
                  type="text"
                  name="team_member_phone_number"
                  @blur="updateTeamInfo"
                  v-model="input.phoneNumber"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <label class="label is-size-7">Image</label>
          <div class="field is-grouped is-image"  style="cursor: pointer;">
            <div class="field">
              <div class="box has-background-light" style="padding: 10px; width:150px;" @click="element_clicker('#file')">
                <div class="image">
                  <img :src="input.avatarPath.includes('blob:') ? input.avatarPath : input.avatarPath + '?ref=' +  new Date().getTime()"/>
                </div>
              </div>
            </div>
            <div class="field" style="padding-left: 20px;">
              <div class="field" style="cursor: pointer;">
                <input
                  :disabled="!$hasPermissions(clientSession, ['USERS'], 2)"
                  v-on:change="onAvatarChange"
                  v-show="false"
                  :class="['button is-accent']"
                  id="file"
                  type="file"
                  name="pic"
                  accept="image/*"
                >
                <label class="button is-accent" for="file"  :class="[!$hasPermissions(clientSession, ['USERS'], 2) ? 'disabled' : '']">Replace</label>
              </div>
              <label class="label is-size-7 has-text-warning">Supports GIF, PNG, JPG, and JPEG</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="field">
        <label class="label is-size-7" style="display: block; top: 33px">Bio</label>
        <div class="control">
          <div>
            <div class="content-tab" style="display:block">
              <tinymce @input="updateTeamInfo" id="editor" ref="editor" plugins="code" toolbar="code" v-model="input.bio"></tinymce>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'roleTeamMember',
  props: {
    _errors: {
      type: Object,
      required: false
    },
    model: {
      default: () => {
        return {
          displayName: '',
          title: '',
          phoneNumber: '',
          avatarPath: '',
          bio: ''
        }
      }
    }
  },
  computed: {
    ...mapState(['clientSession'])
  },
  data () {
    return {
      input: {
        displayName: '',
        title: '',
        phoneNumber: '',
        avatarPath: '',
        bio: ''
      },
      customToolbar: [['bold', 'italic', 'underline']],
      showRaw: false
    }
  },
  async created () {
    Object.assign(this.input, this.model)

    if (this.input.avatarPath === '') {
      await this.loadModel('/users')
    }
  },
  mounted () {},
  watch: {},
  methods: {
    async onAvatarChange (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.input.avatarPath = URL.createObjectURL(files[0])
      this.$emit('avatarChange', files[0])
    },
    updateTeamInfo () {
      this.$emit('teamInfoUpdated', this.input)
    }
  }
}
</script>

<style scoped>

  .columns.team-member-properties .column .field {
    display: flex!important;
    flex-direction: column!important;
    flex-wrap: wrap!important;
    justify-content: flex-start!important;
    align-items: flex-start!important;
    align-content: flex-start!important;
    padding-left: 3px!important;
  }
</style>
