<template>
  <div>
    <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
      <span class="has-text-weight-bold">Role Properties</span>
    </div>
    <div class="level">
      <!-- Institution Selection -->
      <div class="level-left" style="width: 45%;">
        <div class="field" style="width: 100%;">
          <label class="label is-size-7">Institution List</label>
          <div class="control">
            <div class="select is-multiple" style="width: 100%;">
              <select
                id="lstAvailableInstitutions"
                multiple
                v-model="selectedAvailableInstitutionIds"
                :size="listSize"
                style="width: 100%;"
              >
                <option v-for="id in availableInstitutionIds" :value="id" :key="id">{{institutions.get(id).id}} {{ institutions.get(id).name }}</option>
                <!-- <option v-if="this.selectedAvailableInstitutionIds.length === 0" disabled>All Institutions Selected</option> -->
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="level-center" style="width: 10%;">
        <div id="buttonblock" class="has-text-centered">
          <div class="block">
            <a v-on:click="moveInstitutionRight" class="button is-accent is-outlined">
              <i class="fas fa-caret-right fa-2x"></i>
            </a>
          </div>
          <div class="block">
            <a v-on:click="moveInstitutionLeft" class="button is-accent is-outlined">
              <i class="fas fa-caret-left fa-2x"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="level-right" style="width: 45%;">
        <div class="field" style="width: 100%;">
          <label class="label is-size-7">
            Permission to Access Institutions
            <span
              class="has-text-warning"
              style="padding-left: 3px;"
            >*</span>
          </label>
          <div class="control"  style="  z-index: 0 !important;">
            <div :class="{ 'select is-multiple': true, 'is-danger': _errors.institutionId !== undefined }" style="width: 100%;">
              <select
                id="lstPermittedInstitutions"
                multiple
                v-model="selectedPermittedInstitutionIds"
                :size="listSize"
                selectedAvailableInstitutionIds
                style="width: 100%;"
              >
                <option v-if="input.permittedInstitutionIds.length === 0" disabled>Select an Institution</option>
                <option
                  v-for="id in input.permittedInstitutionIds"
                  :key="id"
                  :value="id"
                >{{institutions.get(id).id}} {{ institutions.get(id).name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="level">
      <!-- Report Type Selection -->
      <div class="level-left" style="width: 45%;">
        <div class="field" style="width: 100%;">
          <label class="label is-size-7">Report Type List</label>
          <div class="control">
            <div :class="{ 'select is-multiple': true}" style="width: 100%;">
              <select id="lstAvailableReportTypes" multiple v-model="selectedAvailableReportTypeCodes" :size="listSize" style="width: 100%;">
                <!-- <option v-if="this.selectedAvailableReportTypeCodes.length === 0" disabled>All Report Types Selected</option> -->
                <option v-for="code in availableReportTypeCodes" :value="code" :key="code">{{reportTypes.get(code).name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="level-center" style="width: 10%;">
        <div id="buttonblock" class="has-text-centered">
          <div class="block">
            <a v-on:click="moveReportTypeRight" class="button is-accent is-outlined">
              <i class="fas fa-caret-right fa-2x"></i>
            </a>
          </div>
          <div class="block">
            <a v-on:click="moveReportTypeLeft" class="button is-accent is-outlined">
              <i class="fas fa-caret-left fa-2x"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="level-right" style="width: 45%;">
        <div class="field" style="width: 100%;">
          <label class="label is-size-7">
            Permission to Access Report Types
            <span
              class="has-text-warning"
              style="padding-left: 3px;"
            >*</span>
          </label>
          <div class="control"  style="  z-index: 0 !important;">
            <div :class="{ 'select is-multiple': true, 'is-danger': _errors.reportTypeCodes !== undefined }" style="width: 100%;">
              <select id="lstPermittedReportTypes" multiple v-model="selectedPermittedReportTypeCodes" :size="listSize" style="width: 100%;">
                <option v-if="input.permittedReportTypeCodes.length===0" disabled>Select a Report Type</option>
                <option
                  v-for="code in input.permittedReportTypeCodes"
                  :key="code"
                  :value="code"
                >{{reportTypes.get(code).name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'roleOutsideSales',
  props: {
    _errors: {
      type: Object,
      required: false
    },
    model: {
      default: () => {
        return {
          userId: null,
          institutionIds: [],
          reportTypeCodes: []
        }
      }
    }
  },
  data: function () {
    return {
      input: {
        permittedInstitutionIds: [],
        permittedReportTypeCodes: []
      },
      listSize: 7,
      selectedPermittedInstitutionIds: [],
      selectedAvailableInstitutionIds: [],
      institutions: new Map(),
      selectedPermittedReportTypeCodes: [],
      selectedAvailableReportTypeCodes: [],
      reportTypes: new Map()
    }
  },
  async created () {
    await this.getReportTypesLookup()
    await this.getInstitutionsLookup()
    this.input.permittedInstitutionIds = this.model.institutionIds.sort() // TODO: think vue warns against modifying prop
    this.input.permittedReportTypeCodes = this.model.reportTypeCodes
  },
  computed: {
    institutionIds () {
      return [ ...this.institutions.keys() ]
    },
    availableInstitutionIds () {
      return this.institutionIds.filter(x =>
        !this.input.permittedInstitutionIds.includes(x)
      )
    },
    reportTypeCodes () {
      return [...this.reportTypes.keys()]
    },
    availableReportTypeCodes () {
      return this.reportTypeCodes.filter(x => !this.input.permittedReportTypeCodes.includes(x))
    }
  },
  methods: {
    buildMembership () {
      return { userId: null, institutionIds: this.input.permittedInstitutionIds, reportTypeCodes: this.input.permittedReportTypeCodes }
    },
    async getReportTypesLookup () {
      // TODO: dedupe
      try {
        let route = `/report-types`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.reportTypes = new Map(
          response.data.records.map(i => [i.code, i])
        )
        this.reportTypesArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getInstitutionsLookup () {
      // TODO: dedupe
      try {
        let route = `/institutions`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.institutions = new Map(
          response.data.records.map(i => [i.id, i])
        )
        this.institutionsArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    moveInstitutionRight (event) {
      this.input.permittedInstitutionIds.push(...this.selectedAvailableInstitutionIds)
      this.input.permittedInstitutionIds = this.input.permittedInstitutionIds.sort()
      this.selectedAvailableInstitutionIds = [] // this resolves an issue where if the last id in the list is selected and moved its never unselected by the control unlike moving other indexes from the list
      this.$emit('membershipUpdated', this.buildMembership())
    },
    moveInstitutionLeft (event) {
      this.input.permittedInstitutionIds = this.input.permittedInstitutionIds.filter(x =>
        !this.selectedPermittedInstitutionIds.includes(x)
      )
      this.input.permittedInstitutionIds = this.input.permittedInstitutionIds.sort()
      this.selectedPermittedInstitutionIds = []
      this.$emit('membershipUpdated', this.buildMembership())
    },
    moveReportTypeRight (event) {
      this.input.permittedReportTypeCodes.push(...this.selectedAvailableReportTypeCodes)
      this.input.permittedReportTypeCodes = this.input.permittedReportTypeCodes.sort()
      this.selectedAvailableReportTypeCodes = []
      this.$emit('membershipUpdated', this.buildMembership())
    },
    moveReportTypeLeft (event) {
      this.input.permittedReportTypeCodes = this.input.permittedReportTypeCodes.filter(x =>
        !this.selectedPermittedReportTypeCodes.includes(x)
      )
      this.input.permittedReportTypeCodes = this.input.permittedReportTypeCodes.sort()
      this.selectedPermittedReportTypeCodes = []
      this.$emit('membershipUpdated', this.buildMembership())
    }
  }
}
</script>
