<template>
  <div>
    <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
      <span class="has-text-weight-bold">Role Properties</span>
    </div>
    <div class="field">
      <label class="label is-size-7">
        Institution
        <span class="has-text-warning" style="padding-left: 3px;">*</span>
      </label>
      <div class="control inline">
        <div class="select">
          <select :class="{ 'input': true, 'is-danger': errors.institutionId !== undefined }" @change="handleInstitutionUpdate" v-model="input.institutionId" >
            <option v-for="institution in institutionsArr" :value="institution.id" :key="institution.id">
              <h2>{{institution.id}} {{ institution.name }}</h2>
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="level">
      <div class="level-left" style="width: 45%;">
        <div class="field" style="width: 100%;">
          <label class="label is-size-7">Report Type List</label>
          <div class="control">
            <div class="select is-multiple" style="width: 100%;">
              <select id="lstAvailableReportTypes" multiple v-model="selectedAvailableReportTypeCodes" :size="listSize" style="width: 100%;">
                <!-- <option v-if="this.selectedAvailableReportTypeCodes.length === 0" disabled>All Report Types Selected</option> -->
                <option v-for="code in sort(availableReportTypeCodes)" :value="code.code" :key="code.code">{{code.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="level-center" style="width: 10%;">
        <div id="buttonblock" class="has-text-centered">
          <div class="block">
            <a v-on:click="moveReportTypeRight" class="button is-accent is-outlined">
              <i class="fas fa-caret-right fa-2x"></i>
            </a>
          </div>
          <div class="block">
            <a v-on:click="moveReportTypeLeft" class="button is-accent is-outlined">
              <i class="fas fa-caret-left fa-2x"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="level-right" style="width: 45%;">
        <div class="field" style="width: 100%;">
          <label class="label is-size-7">
            Permission to Access Report Types
            <span
              class="has-text-warning"
              style="padding-left: 3px;"
            >*</span>
          </label>
          <div class="control" style="  z-index: 0 !important;">
            <div :class="{ 'select is-multiple': true, 'is-danger': errors.reportTypeCodes !== undefined }" style="width: 100%;">
              <select id="lstPermittedReportTypes" multiple v-model="selectedPermittedReportTypeCodes" :size="listSize" style="width: 100%;">
                <option v-if="input.permittedReportTypeCodes.length===0" disabled>Select a Report Type</option>
                <option v-for="code in sort(input.permittedReportTypeCodes)" :key="code.code" :value="code.code">{{code.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import _ from 'underscore'

export default {
  name: 'roleClient',
  props: {
    errors: {
      type: Object,
      required: false
    },
    model: {
      default: () => {
        return {
          id: '',
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          phone: '',
          title: '',
          bio: '',
          extension: ''
        }
      }
    }
  },
  data: function () {
    return {
      input: {
        institutionId: 0,
        permittedReportTypeCodes: []
      },
      customToolbar: [['bold', 'italic', 'underline']],
      activetab: 0,
      listSize: 7,
      institutions: new Map(),
      institutionsArr: [],
      selectedPermittedReportTypeCodes: [],
      selectedAvailableReportTypeCodes: [],
      reportTypes: new Map()
    }
  },
  async created () {
    await this.getReportTypesLookup()
    await this.getInstitutionsLookup()
    if (this.model.institutionIds.length > 0) { this.input.institutionId = this.model.institutionIds[0] } // TODO: think vue warns against modifying prop
    this.input.permittedReportTypeCodes = this.model.reportTypeCodes
  },
  computed: {
    reportTypeCodes () {
      return [...this.reportTypes.keys()]
    },
    availableReportTypeCodes () {
      return this.reportTypeCodes.filter(x => !this.input.permittedReportTypeCodes.includes(x))
    }
  },
  methods: {
    sort (arrays) {
      let that = this
      let objects = []

      arrays.map(code => {
        if (Array.from(that.reportTypes.keys()).includes(code)) {
          let data = that.reportTypes.get(code)

          if (data) {
            objects.push(data)
          }
        }
      })

      return _.sortBy(objects, 'name')
    },
    async getReportTypesLookup () {
      // TODO: dedupe
      try {
        let route = `/report-types`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.reportTypes = new Map(
          response.data.records.map(i => [i.code, i])
        )
        this.reportTypesArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getInstitutionsLookup () {
      // TODO: dedupe
      try {
        let route = `/institutions`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.institutions = new Map(
          response.data.records.map(i => [i.id, i])
        )
        this.institutionsArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    buildMembership () {
      return { userId: this.model.id, institutionIds: [ this.input.institutionId ], reportTypeCodes: this.input.permittedReportTypeCodes }
    },
    handleInstitutionUpdate (event) {
      this.$emit('membershipUpdated', this.buildMembership())
    },
    moveReportTypeRight (event) {
      this.input.permittedReportTypeCodes.push(...this.selectedAvailableReportTypeCodes)
      this.input.permittedReportTypeCodes = this.input.permittedReportTypeCodes
      this.selectedAvailableReportTypeCodes = []
      this.$emit('membershipUpdated', this.buildMembership())
    },
    moveReportTypeLeft (event) {
      this.input.permittedReportTypeCodes = this.input.permittedReportTypeCodes.filter(x =>
        !this.selectedPermittedReportTypeCodes.includes(x)
      )
      this.input.permittedReportTypeCodes = this.input.permittedReportTypeCodes
      this.selectedPermittedReportTypeCodes = []
      this.$emit('membershipUpdated', this.buildMembership())
    }
  }
}
</script>
